import { ethers, ContractFactory } from 'ethers';
import axios from "axios";
import WalletConnectProvider from '@walletconnect/web3-provider'
 

const tokenURIPrefix = gon.tokenURIPrefix;
const transferProxyContractAddress = gon.transferProxyContractAddress;
const wmaticAddress = gon.wmaticAddress;
const tradeContractAddress = gon.tradeContractAddress;
const factoryContractAddressFor721 = gon.factoryContractAddressFor721
const factoryContractAddressFor1155 = gon.factoryContractAddressFor1155
let account;
const sessionWallet = gon.wallet;
const sessionAddress = gon.address
const chainId = gon.chainId;
let walletConnect;
const rpcUrl = gon.ethereum_provider
let signer;
let provider;

async function loadWeb3() {
  if (window.ethereum && (window.wallet == 'metamask' || typeof window.wallet == "undefined")) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
    await ethereum.request({ method: 'eth_requestAccounts' })
    gon.provider = provider    

    return signer.getAddress();
  }else if (window.wallet == 'walletConnect') { 
   walletConnect = new WalletConnectProvider({
      rpc: {
        [chainId]: rpcUrl,
      }
    });
   const address = await walletConnect.enable();
   window.provider = new ethers.providers.Web3Provider(walletConnect);
   window.signer = window.provider.getSigner();
   //const address = await walletConnect.enable();
   return address[0] ?? '';
  }
  
}
async function getaccounts() {
  try {
  if (window.wallet == 'walletConnect') {
    const signer = window.provider.getSigner();
    var accounts = await signer.getAddress();
  }else{
    const signer = provider.getSigner();
    var accounts = await signer.getAddress();
    }
    return accounts;
  } catch (e) {
    console.log(e)
  }
}

function getInfuraId(){
  const url = new URL(rpcUrl);
  const path = url.pathname.split('/');
  return path[path.length-1] ?? '';
}

async function createUserSession(address, balance, destroySession, wallet = window.wallet) {

  const config = {
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const resp = await axios.post(`/sessions`, {
    address: address,
    balance: balance,
    destroy_session: destroySession,
    wallet
  }, config)
    .then((response) => {
      return resp
    })
    .catch(err => {
    })
  return resp;
}

async function destroyUserSession(address) {
  const config = {
    data: {},
    headers: {
      'X-CSRF-TOKEN': $('[name="csrf-token"]')[0].content,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const resp = axios.delete(`/sessions/${address}`, config)
    .then(response => response)
    .catch(err =>{})
  return resp
}

function updateTokenId(tokenId, collectionId) {
  var request = $.ajax({
    url: `/collections/${collectionId}/update_token_id`,
    async: false,
    type: "POST",
    data: {tokenId: tokenId, collectionId: collectionId},
    dataType: "script"
  });
  request.done(function (msg) {
  });
  request.fail(function (jqXHR, textStatus) {
  });
}

function saveContractNonceValue(collectionId, sign) {
  var request = $.ajax({
    url: `/collections/${collectionId}/save_contract_nonce_value`,
    async: false,
    type: "POST",
    data: {signature : sign},
    dataType: "script"
  });
  request.done(function (msg) {
  });
  request.fail(function (jqXHR, textStatus) {
  });
}

function createContract(name, symbol, contract_address, contractType, collectionId) {
  var request = $.ajax({
    url: '/users/create_contract',
    async: false,
    type: "POST",
    data: {
      name: name,
      symbol: symbol,
      contract_address: contract_address,
      contract_type: contractType,
      collection_id: collectionId
    },
    dataType: "script"
  });
  request.done(function (msg) {
  });
  request.fail(function (jqXHR, textStatus) {
  });
}

function updateCollectionBuy(collectionId, quantity, transactionHash) {
  var request = $.ajax({
    url: '/collections/' + collectionId + '/buy',
    type: 'POST',
    async: false,
    data: {quantity: quantity, transaction_hash: transactionHash},
    dataType: "script",
    success: function (respVal) {
    }
  });
}

function updateCollectionSell(collectionId, buyerAddress, bidId, transactionHash) {
  var request = $.ajax({
    url: '/collections/' + collectionId + '/sell',
    type: 'POST',
    async: false,
    data: {address: buyerAddress, bid_id: bidId, transaction_hash: transactionHash},
    dataType: "script",
    success: function (respVal) {
    }
  });
}

function updateOwnerTransfer(collectionId, recipientAddress, transactionHash, supply) {
  var request = $.ajax({
    url: '/collections/' + collectionId + '/owner_transfer',
    type: 'POST',
    async: false,
    data: {recipient_address: recipientAddress, transaction_hash: transactionHash, supply: supply},
    dataType: "script",
    success: function (respVal) {
    }
  });
}

function updateBurn(collectionId, transactionHash, supply) {
  var request = $.ajax({
    url: '/collections/' + collectionId + '/burn',
    type: 'POST',
    async: false,
    data: {transaction_hash: transactionHash, supply: supply},
    dataType: "script",
    success: function (respVal) {
    }
  });
}

async function isValidUser(address, token, wallet) {
  const config = {
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const resp = await axios.get(`/sessions/valid_user`, {params: {address: address, authenticity_token: token, wallet}}, config)
    .then((response) => {
      return response.data
    })
    .catch(err => {
    })
  return resp;
}

function placeBid(collectionId, sign, quantity, bidDetails) {
  var request = $.ajax({
    url: `/collections/${collectionId}/bid`,
    type: "POST",
    async: false,
    data: {sign: sign, quantity: quantity, details: bidDetails},
    dataType: "script"
  });
  request.done(function (msg) {
  });
  request.fail(function (jqXHR, textStatus) {
  });
}

function signMetadataHash(collectionId, contractAddress) {
  var sign;
  var request = $.ajax({
    url: `/collections/${collectionId}/sign_metadata_hash`,
    type: "POST",
    async: false,
    data: {contract_address: contractAddress},
    dataType: "json"
  });
  request.done(function (msg) {
    sign = {sign: msg['signature'], nonce: msg['nonce']}
  });
  request.fail(function (jqXHR, textStatus) {
  });
  return sign
}

function updateSignature(collectionId, sign) {
  var request = $.ajax({
    url: `/collections/${collectionId}/sign_fixed_price`,
    type: "POST",
    async: false,
    data: {sign: sign},
    dataType: "script"
  });
  request.done(function (msg) {
  });
  request.fail(function (jqXHR, textStatus) {
  });
}

function getNonceValue(collectionId) {
  var nonce;
  var request = $.ajax({
    url: `/collections/${collectionId}/get_nonce_value`,
    type: "POST",
    async: false,
    data: {},
    dataType: "json"
  });
  request.done(function (data) {
    nonce = data['nonce']
  });
  request.fail(function (jqXHR, textStatus) {
  });
  return nonce
}

function save_NonceValue(collectionId, sign, nonce) {
  var request = $.ajax({
    url: `/collections/${collectionId}/save_nonce_value`,
    type: "POST",
    async: false,
    data: {sign: sign, nonce: nonce},
    dataType: "script"
  });
  request.done(function (msg) {
  });
  request.fail(function (jqXHR, textStatus) {
  });
}

function getContractSignNonce(collectionId, sign) {
  var nonce;
  var request = $.ajax({
    url: `/collections/${collectionId}/get_contract_sign_nonce`,
    type: "POST",
    async: false,
    data: {sign: sign},
    dataType: "json"
  });
  request.done(function (data) {
    nonce = data['nonce']
  });
  request.fail(function (jqXHR, textStatus) {
  });
  return nonce
}

window.getContractABIAndBytecode = function getContractABIAndBytecode(contractAddress, type, shared = true) {
  var res;
  var request = $.ajax({
    async: false,
    url: '/contract_abi',
    type: "GET",
    data: {contract_address: contractAddress, type: type, shared: shared},
    dataType: "json"
  });

  request.done(function (msg) {
    res = msg;
  });

  request.fail(function (jqXHR, textStatus) {
  });
  return res;
}

function splitSign(sign, nonce) {
  let sig = ethers.utils.splitSignature(sign);
  return [sig.v,sig.r,sig.s, nonce];
}

window.getContract = async function getContract(contractAddress, type, shared = true, abi_factory = false) {
  var res = getContractABIAndBytecode(contractAddress, type, shared);
  const abi = abi_factory ? res['compiled_contract_details']['abi_factory'] : res['compiled_contract_details']['abi'];
  var contractObj = new ethers.Contract(contractAddress,abi, provider);
  return contractObj
}

window.createCollectible721 = async function createCollectible721(contractAddress, tokenURI, royaltyFee, collectionId, sharedCollection) {
  try {
    var account = getCurrentAccount()
    let contract721 = await getContract(contractAddress, 'nft721', sharedCollection);
    let contract721WithSigner = contract721.connect(signer);    
    window.contract721 = contract721WithSigner;
    var gasPrices = await gasPrice();
    var txn;
    if (sharedCollection) {
      var sign = await signMetadataHash(collectionId, contractAddress);
      await saveContractNonceValue(collectionId, sign)
      var signStruct = splitSign(sign['sign'], sign['nonce']);
      var txn = await window.contract721.createCollectible(tokenURI, royaltyFee, signStruct,{
        from: account,
        gasPrice: String(gasPrices)
      });
    } else {
       txn = await window.contract721.createCollectible(tokenURI, royaltyFee,{
        from: account,
        gasPrice: String(gasPrices)
      });
    }
    txn = await txn.wait();
    var tokenId = parseInt(txn.logs[1].topics[1]);
    await updateTokenId(tokenId, collectionId)
    return window.collectionMintSuccess(collectionId)
  } catch (err) {
    console.error(err);
    return window.collectionMintFailed(err['message'])
  }
}

window.createCollectible1155 = async function createCollectible1155(contractAddress, supply, tokenURI, royaltyFee, collectionId, sharedCollection) {
  try {
    var account = getCurrentAccount()
    let contract1155 = await getContract(contractAddress, 'nft1155', sharedCollection);
    let contract1155WithSigner = contract1155.connect(signer);  
    window.contract1155 = contract1155WithSigner;
    var gasPrices = await gasPrice();
    var txn;
    if (sharedCollection) {
      var sign = await signMetadataHash(collectionId, contractAddress);
      await saveContractNonceValue(collectionId, sign)
      var signStruct = splitSign(sign['sign'], sign['nonce']);
      var txn = await window.contract1155.mint(tokenURI, supply, royaltyFee, signStruct,{
        from: account,
        gasPrice: String(gasPrices)
      });
    } else {
      txn = await window.contract1155.mint(tokenURI, supply, royaltyFee,{
        from: account,
        gasPrice: String(gasPrices)
      });
    }
    txn = await txn.wait();
    var tokenId = parseInt(txn.logs[1].topics[1]);
    await updateTokenId(tokenId, collectionId)
    return window.collectionMintSuccess(collectionId)
  } catch (err) {
    console.error(err);
    return window.collectionMintFailed(err['message'])
  }
}

function getRandom(address) {
  let value = Date.now() + Math.floor((Math.random() * (10 ** 10)) + 1);
  var hex = value.toString(16);
  hex = hex + address.slice(2);
  return `0x${'0'.repeat(64-hex.length)}${hex}`;
}

window.deployContract = async function deployContract(abi, bytecode, name, symbol, contractType, collectionId) {
  var contractAddress;
  var account = window.ethereum.selectedAddress
  var salt = getRandom(account);
  var contractDeploy;
  try{
    if (contractType == 'nft721') {
       contractDeploy = await getContract(factoryContractAddressFor721, 'nft721', false, true)
      }
    else if(contractType == 'nft1155') {
      contractDeploy = await getContract(factoryContractAddressFor1155, 'nft1155', false, true)
    }
    contractDeploy = contractDeploy.connect(provider.getSigner());
    var deployment = await contractDeploy.deploy(salt, name, symbol, tokenURIPrefix);
    deployment = await deployment.wait();
    contractAddress = deployment.events[2].args.contractAddress;
    $('#nft_contract_address').val(contractAddress);
    createContract(name, symbol, contractAddress, contractType, collectionId);
    window.contractDeploySuccess(contractAddress, contractType)
 } catch(err) {
    console.error(err);
    window.contractDeployFailed(err['message'])
  };
}

window.approveNFT = async function approveNFT(contractType, contractAddress, sharedCollection, sendBackTo = 'collection') {
  try {
    var account = getCurrentAccount()
    let contractNFT = await getContract(contractAddress, contractType, sharedCollection);
    window.contract = contractNFT.connect(signer);    
    var gasPrices = await gasPrice();
    var isApproved = await window.contract.isApprovedForAll(account, transferProxyContractAddress);
    if (!isApproved) {
      var receipt = await window.contract.setApprovalForAll(transferProxyContractAddress, true, {from: account});
      receipt = await receipt.wait();
    }
    if (sendBackTo == 'executeBid') {
      return window.approveBidSuccess()
    } else {
      return window.collectionApproveSuccess(contractType);
    }
  } catch (err) {
    console.error(err);
    if (sendBackTo == 'executeBid') {
      return window.approveBidFailed(err['message'])
    } else {
      return window.collectionApproveFailed(err['message'])
    }
  }
}

window.approveResaleNFT = async function approveResaleNFT(contractType, contractAddress, sharedCollection) {
  try {
    var account = getCurrentAccount()
    let contractNFT = await getContract(contractAddress, contractType, sharedCollection);
    window.contract = contractNFT.connect(signer);
    var gasPrices = await gasPrice();
    var isApproved = await window.contract.isApprovedForAll(account, transferProxyContractAddress);
    if (!isApproved) {
      var receipt = await window.contract.setApprovalForAll(transferProxyContractAddress, true,{from: account});
      receipt = await receipt.wait();
    }
    return window.approveResaleSuccess(contractType);
  } catch (err) {
    console.error(err);
    return window.approveResaleFailed(err['message'])
  }
}

//TODO: OPTIMIZE
window.isApprovedNFT = async function isApprovedNFT(contractType, contractAddress) {
  try {
    var contractNFT = await getContract(contractAddress, contractType);
    var account = await getaccounts()
    var isApproved = await contractNFT.isApprovedForAll(account, transferProxyContractAddress);
    return isApproved;
  } catch (err) {
    console.error(err);
  }
}

window.burnNFT = async function burnNFT(contractType, contractAddress, tokenId, supply = 1, collectionId, sharedCollection) {
  try {
    var contractNFT = await getContract(contractAddress, contractType, sharedCollection);
    const contract = contractNFT.connect(signer);
    var account = await getaccounts()
    var receipt;
    var gasPrices = await gasPrice();
    if (contractType == 'nft721') {
       receipt = await contract.burn(tokenId);
    } else if (contractType == 'nft1155') {
       receipt = await contract.burn(tokenId, supply);
    }
    receipt = await receipt.wait();
    await updateBurn(collectionId, receipt.transactionHash, supply)
    return window.burnSuccess(receipt.transactionHash);
  } catch (err) {
    console.error(err);
    return window.burnFailed(err['message'])
  }
}

window.directTransferNFT = async function directTransferNFT(contractType, contractAddress, recipientAddress, tokenId, supply = 1, shared, collectionId) {
  try {
    var contractNFT = await getContract(contractAddress, contractType, shared);
    const contract = contractNFT.connect(signer);    
    var account = getCurrentAccount();
    var receipt;
    var gasPrices = await gasPrice();
    if (contractType == 'nft721') {
       receipt = await contract["safeTransferFrom(address,address,uint256)"](account, recipientAddress, tokenId);
    } else if (contractType == 'nft1155') {
      // TODO: Analyse and use proper one in future
      var tempData = "0x6d6168616d000000000000000000000000000000000000000000000000000000"
       receipt = await contract["safeTransferFrom(address,address,uint256,uint256,bytes)"](account, recipientAddress, tokenId, supply, tempData, {from: account});
    }
    receipt = await receipt.wait();
    await updateOwnerTransfer(collectionId, recipientAddress, receipt.transactionHash,supply)
    return window.directTransferSuccess(receipt.transactionHash, collectionId);
  } catch (err) {
    console.error(err);
    return window.directTransferFailed(err['message']);
  }
}

window.approveERC20 = async function approveERC20(contractAddress, contractType, amount, decimals = 18, sendBackTo = 'Bid') {
  try {
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);

    var compiledContractDetails = getContractABIAndBytecode(contractAddress, contractType, gon.collection_data['contract_shared']);
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contractNFT = await getContract(contractAddress, contractType);
    const contract = contractNFT.connect(signer);

    var account = getCurrentAccount();

    var balance = await contract.allowance(account, transferProxyContractAddress);
    amount = BigInt(parseInt(balance) + parseInt(amount)).toString();
    var receipt = await contract.approve(transferProxyContractAddress, amount,{from: account});
    receipt = await receipt.wait();
    if (sendBackTo == 'Buy') {
      return window.buyApproveSuccess(receipt.transactionHash, contractAddress)
    } else {
      return window.bidApproveSuccess(receipt.transactionHash, contractAddress)
    }
  } catch (err) {
    console.error(err);
    if (sendBackTo == 'Buy') {
      return window.buyApproveFailed(err['message'])
    } else {
      return window.bidApproveFailed(err['message'])
    }
  }
}

window.approvedTokenBalance = async function approvedTokenBalance(contractAddress) {
  var contract = await getContract(contractAddress, 'erc20', shared);
  var account = await getaccounts()
  var balance = await contract.allowance(account, transferProxyContractAddress);
  return balance;
}

window.convertWMATIC = async function convertWMATIC(amount, sendBackTo = 'Bid', decimals = 18) {
  try {
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
    var compiledContractDetails = getContractABIAndBytecode(wmaticAddress, 'erc20');
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    
    var contractNFT = await new ethers.Contract(wmaticAddress, abi, provider);
    const contract = contractNFT.connect(signer);

    var account = getCurrentAccount()
    var gasPrices = await gasPrice();
    var receipt = await contract.deposit({value: amount, gasPrice: String(gasPrices)});
    receipt = await receipt.wait();

    if (sendBackTo == 'Buy') {
      return window.buyConvertSuccess(receipt.transactionHash)
    } else {
      return window.bidConvertSuccess(receipt.transactionHash)
    }
  } catch (err) {
    console.error(err);
    if (sendBackTo == 'Buy') {
      return window.bidConvertFailed(err['message'])
    } else {
      return window.bidConvertFailed(err['message'])
    }

  }
}

window.updateBuyerServiceFee = async function updateBuyerServiceFee(buyerFeePermille) {
  try {
    var compiledContractDetails = getContractABIAndBytecode(tradeContractAddress, 'trade');
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contractNFT = await new ethers.Contract(abi, tradeContractAddress, provider);
    const contract = contractNFT.connect(signer); 
    var account = getCurrentAccount()
    var gasPrices = await gasPrice();
    var receipt = await contract.setBuyerServiceFee(buyerFeePermille,{from: account});
    receipt = await receipt.wait();
    if(String(receipt.status) === "true"){
      $("form#fee_form").submit();
      $("div.loading-gif.displayInMiddle").hide()
    }

  } catch (err) {
    return false
    console.error(err);
  }
}

window.updateSellerServiceFee = async function updateSellerServiceFee(sellerFeePermille) {
  try {
    var compiledContractDetails = getContractABIAndBytecode(tradeContractAddress, 'trade');
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contractNFT = await new ethers.Contract(abi, tradeContractAddress);
    const contract = contractNFT.connect(signer);
    var account = getCurrentAccount()
    var gasPrices = await gasPrice();
    var receipt = await contract.setSellerServiceFee(sellerFeePermille,{from: account});
    receipt = await receipt.wait();
    if(String(receipt.status) === "true"){
      $("form#fee_form").submit();
      $("div.loading-gif.displayInMiddle").hide();
    }
  } catch (err) {
    console.error(err);
   
  }
}

window.bidAsset = async function bidAsset(assetAddress, tokenId, qty = 1, amount, payingTokenAddress, decimals = 18, collectionId, bidPayAmt) {
  try {
    var amountInDec = roundNumber(mulBy(amount, 10 ** decimals), 0);
    var nonce_value = await getNonceValue(collectionId);

    var messageHash = ethers.utils.solidityKeccak256(['address', 'uint256', 'address', 'uint256', 'uint256', 'uint256'], [assetAddress, tokenId, payingTokenAddress, amountInDec, qty, nonce_value]);
    messageHash = await ethers.utils.arrayify(messageHash);
    if (window.wallet == 'walletConnect') {
      const signer = window.provider.getSigner();
      var signature = await signer.signMessage(messageHash);
    } else {
     var signature = await signer.signMessage(messageHash);
    }
    await placeBid(collectionId, signature, qty, {
      asset_address: assetAddress,
      token_id: tokenId,
      quantity: qty,
      amount: bidPayAmt,
      amount_with_fee: amount,
      payment_token_address: payingTokenAddress,
      payment_token_decimals: decimals
    })
    await save_NonceValue(collectionId, signature, nonce_value)
    return window.bidSignSuccess(collectionId)
  } catch (err) {
    console.error(err);
    return window.bidSignFailed(err['message'])
  }
}

window.signMessage = async function signMessage(msg) {
  try {
    var account = getCurrentAccount();
    if (window.wallet == 'walletConnect') {
      const signer = window.provider.getSigner();
      var sign = signer.signMessage(msg);
    }else{
      var sign = signer.signMessage(msg);
    }
    return sign;
  } catch (err) {
    console.log(err);
    return ""
  }
}

window.signSellOrder = async function signSellOrder(amount, decimals, paymentAssetAddress, tokenId, assetAddress, collectionId, sendBackTo='') {
  try {
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
    var nonce_value = await getNonceValue(collectionId);
    var messageHash = ethers.utils.solidityKeccak256(["address","uint256","address","uint256","uint256"],[assetAddress, tokenId, paymentAssetAddress, amount, nonce_value]);
    messageHash = ethers.utils.arrayify(messageHash);
    var account = getCurrentAccount()
    if (window.wallet == 'walletConnect') {
      const signer = window.provider.getSigner();
      var fixedPriceSignature = await signer.signMessage(messageHash, account);
     }else{
      var fixedPriceSignature = await signer.signMessage(messageHash, account);
     }
    await updateSignature(collectionId, fixedPriceSignature)
    await save_NonceValue(collectionId, fixedPriceSignature, nonce_value)
    if (sendBackTo == 'update') {
      return window.updateSignFixedSuccess(collectionId)
    } else {
      return window.bidSignFixedSuccess(collectionId)
    }
  } catch (err) {
    console.error(err);
    if(sendBackTo == 'update'){
      return window.updateSignFixedFailed(err['message'])
    }else{
      return window.bidSignFixedFailed(err['message'])
    }
  }
}

// buyingAssetType = 1 # 721
// buyingAssetType = 0 # 1155
window.buyAsset = async function buyAsset(assetOwner, buyingAssetType, buyingAssetAddress, tokenId, unitPrice, buyingAssetQty,
                                          paymentAmt, paymentAssetAddress, decimals, sellerSign, collectionId) {
  try {
    paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    unitPrice = roundNumber(mulBy(unitPrice, 10 ** decimals), 0);
    var compiledContractDetails = getContractABIAndBytecode(tradeContractAddress, 'trade');
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contractNFT = await new ethers.Contract(tradeContractAddress, abi, provider);
    const contract = contractNFT.connect(signer);
    var nonce_value = await getContractSignNonce(collectionId, sellerSign);
    var account = getCurrentAccount()  
    var orderStruct = [
      assetOwner,
      account,
      paymentAssetAddress,
      buyingAssetAddress,
      buyingAssetType,
      unitPrice,
      paymentAmt,
      tokenId,
      buyingAssetQty
    ]
    var gasPrices = await gasPrice();
    var receipt = await contract.buyAsset(
      orderStruct,
      splitSign(sellerSign, nonce_value)
    ,{from: account});
    receipt = await receipt.wait();
    await updateCollectionBuy(collectionId, buyingAssetQty, receipt.transactionHash)
    return window.buyPurchaseSuccess(collectionId)
  } catch (err) {
    console.error(err);
    return window.buyPurchaseFailed(err['message'])
  }
}


window.executeBid = async function executeBid(buyer, buyingAssetType, buyingAssetAddress, tokenId, paymentAmt, buyingAssetQty, paymentAssetAddress, decimals, buyerSign, collectionId, bidId) {
  try {
    paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    var unitPrice = 1;
    var compiledContractDetails = getContractABIAndBytecode(tradeContractAddress, 'trade');
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contractNFT = await new ethers.Contract( tradeContractAddress, abi, provider);
    const contract = contractNFT.connect(signer);
    var nonce_value = await getContractSignNonce(collectionId, buyerSign);

    var account = await getaccounts();
    // supply, tokenURI, royalty needs to be passed but WILL NOT be used by the Contract   
    var orderStruct = [
      account,
      buyer,
      paymentAssetAddress,
      buyingAssetAddress,
      buyingAssetType,
      unitPrice,
      paymentAmt,
      tokenId,   
      buyingAssetQty
    ]
    var gasPrices = await gasPrice(); 
    var receipt = await contract.executeBid(
      orderStruct,
      splitSign(buyerSign, nonce_value),{});
      receipt = await receipt.wait();
    await updateCollectionSell(collectionId, buyer, bidId, receipt.transactionHash)
    return window.acceptBidSuccess(collectionId)
  } catch (err) {
    console.error(err);
    return window.acceptBidFailed(err['message'])
  }
}

function getCurrentAccount() {
  if(window.wallet == 'metamask' && window.ethereum.selectedAddress) {
    return window.ethereum.selectedAddress
  }
  return window.currentAddress ?? sessionAddress;
}

window.ethBalance = async function ethBalance(account = '') {
  var account = await getaccounts();
  if(window.wallet == 'metamask'){
     var bal = await signer.getBalance();
  }else{
    const signer = window.provider.getSigner();
    var bal = await signer.getBalance();
  }
  var ethBal = roundNumber(ethers.utils.formatEther(bal), 4);
  console.log(ethBal)
  return ethBal
}

window.updateEthBalance = async function updateEthBalance() {
  var ethBal = await window.ethBalance()
  $('.curBalance').html(ethBal + 'MATIC')
  $('.curEthBalance').text(ethBal)
}

window.tokenBalance = async function tokenBalance(contractAddress, decimals) {
  var abi = [{
    "constant":true,
    "inputs":[{"name":"","type":"address"}],
    "name":"balanceOf",
    "outputs":
    [{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"}]
  var contract;
  if (window.wallet == 'walletConnect') {
    contract = new ethers.Contract( contractAddress, abi, window.provider);
  }
  else if(window.wallet == 'metamask'){
    contract = new ethers.Contract( contractAddress, abi, provider);
  }

  var account = await getaccounts();
  var balance = await contract.balanceOf(account);
  var bal = parseInt(balance);
  balance = roundNumber(divBy(bal, (10 ** decimals)), 4)
  return balance
}

window.getNetworkType = async function getNetworkType() {
  if(window.wallet == 'metamask'){
    var type = await provider.getNetwork();
   }else{
    var type = await window.provider.getNetwork();
   }
  return type["name"];
}

function showTermsCondition(account, ethBal, networkType) {
  var account = account || window.ethereum.selectedAddress
  $.magnificPopup.open({
    closeOnBgClick: false ,
		enableEscapeKey: false,
    items: {
      src: '#terms-and-condition'
    },
    type: 'inline'
  });
  $("#account").val(account)
  $("#eth_balance_tc").val(ethBal)
  $("#network_type").val(networkType)
}

async function load(shouldDestroySession = false) {
  const account = await loadWeb3();
  window.currentAddress = account;
  const ethBal = await ethBalance(account);
  return createDeleteSession(account, ethBal, shouldDestroySession, window.wallet);
}

async function createDeleteSession(account, balance, shouldDestroySession, wallet) {
  const networkType = await getNetworkType();
  const isValidUserResp = await isValidUser(account, '', wallet)
    if (isValidUserResp.user_exists) {
      await createUserSession(account, balance, shouldDestroySession, wallet)
      if (shouldDestroySession) {
        window.location.href = '/'
      } else {
        return true
      }
    } else {
      if (gon.session) {
        if (account) {
          await destroySession()
        }
        window.location.href = '/'
      } else {
        showTermsCondition(account, balance, networkType)
        return false
      }
    }
}

window.disconnect = async function disconnect(address) {
  await destroySession();
  window.location.href = '/';
}

async function destroySession() {
  if (gon.session) {
    await destroyUserSession(getCurrentAccount())
    if(window.wallet === 'walletConnect') {
      walletConnect.disconnect();
    }
  }
}

window.connect = async function connect(wallet = '') {
  if (!wallet) {
    toastr.error('Wallet Required')
    return
  }
  window.wallet = wallet;
  if(typeof web3 === 'undefined' && mobileCheck() && wallet == 'metamask') {
    window.open(`https://metamask.app.link/dapp/` + location.hostname, '_blank').focus();
    return
  }else if (typeof web3 !== 'undefined' || window.wallet == 'walletConnect' ) {
    const status = await load();
    if (status) {
      window.location.href = '/'
    }
  } else {
    toastr.error('Please install Metamask Extension to your browser. <a target="_blank" href="https://metamask.io/download.html">Download Here</a>')
  }
}

window.proceedWithLoad = async function proceedWithLoad() {
  var account = $("#account").val()
  const ethBal = $("#eth_balance").text()
  const networkType = $("#network_type").val()
  if ($("#condition1").is(":checked") && $("#condition2").is(":checked")) {
    await createUserSession(account, ethBal, networkType, window.wallet)
    window.location.href = '/'
  } else {
    toastr.error('Please accept the conditions to proceed')
  }
}

window.loadUser = async function loadUser() {
  let address = '';
  if (gon.session) {
    if(window.wallet == 'walletConnect' && window.web3.currentProvider.connected === false) {
      address = getCurrentAccount();
    }
    if(address) {
      return disconnect();
    }
    load(); 
  }
}

function gasPrice(){
  var init_gasPrice = '100000000000';
 return init_gasPrice;
}
async function loadAddress() {
  if(sessionWallet) {
    window.wallet = sessionWallet
  }
  await loadWeb3();
}

$(function () {
  loadAddress();
});

window.ethereum?.on('accountsChanged', function (acc) {
  if (window.ethereum && gon.session) {
    load(true);
  }
})
